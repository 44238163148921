import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import './App.css'
import Login from './layouts/Login'
import Directlogin from './components/DirectLogin'
import Main from './layouts/Main'
import { ProtectedRoute } from './components/ProtectedRoute'
import { LoginRoute } from './components/LoginRoute';
import { connect } from 'react-redux';
import { setUser } from './redux/actions'
import { LocaleProvider } from 'antd';
import ruRu from 'antd/lib/locale-provider/ru_RU';

const App = () => {

    useEffect(() => {
        if(localStorage.getItem('accessToken') !== null) {
            setUser(localStorage.getItem('login'))
        }
    }, [])

    return (
        <LocaleProvider locale={ruRu}>
            <Router>
                <Switch>
                    <LoginRoute path="/login" component={Login} />
                    <LoginRoute path="/direct/:session" component={Directlogin} />
                    <ProtectedRoute path="/" component={Main} />
                </Switch>
            </Router>
        </LocaleProvider>
    )
}
const mapStateToProps = state => {
    return {
        login: state.user.login,
    }
}

export default connect(mapStateToProps, { setUser })(App)
