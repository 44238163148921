import React from 'react';
import { Link} from 'react-router-dom';
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Input,AutoComplete,Button, Table,  Upload, notification, Form, Modal,Select} from 'antd';
import { useState, useEffect } from 'react';
//import { API_GetHeader } from './../api/getheader';
//import { API_GetResponsible } from './../api/getresponsible';
import { API_GetQuestionList} from '../api/question';
import { API_OOPGet} from '../api/oop';
import { API_getFiles, API_uploadFile, API_deleteFile, API_downloadFile,API_addURL,API_SearchPerson } from './../api/file';
import TextArea from 'antd/lib/input/TextArea';
import {spandata} from './../api/addon';
import {compareByAlph} from './../api/addon';
import getColumnSearchProps from './../components/getColumnSearch'
var moment = require('moment');

//const stud=[0,0,0,0,0,0,0,0,0,0,0, 11,0, 13,0, 15,16, 0, 0, 0, 0, 0,22,23,24,25,26]
//          0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26
const AccredDocUpload = props => {
    const { Option } = Select;
    const [pageName, setPageName] = useState('Загрузка документов');
    const [loading, setLoading] = useState(false);
    const [peopleFiles, setPeopleFiles] = useState([]);
    const [comment, setComment] = useState(undefined);
    const [urldoc, seturldoc] = useState(undefined);
    const [responsible, setapiqueston] = useState([]);
    const [file, setFile] = useState(undefined);
    const [fileList, setFileList] = useState([]);
    const [choicetype,choicetypevalue]= useState(false);
    const [role,setrole]=useState(0);
    const [valueid,setvalueid]=useState(0);
    const [formid,setformid]=useState(0);
    const [code, setcode] = useState(undefined);
    const [fac, setfac] = useState(undefined);
    const [PersondataSource,setdataSource] = useState(undefined);
// делает список вопросов
useEffect(() => {
    API_GetQuestionList(props.match.params.id).then(data => setapiqueston(data));
}, [props.match.params.id]);

Object.size = function(obj) {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};


const roles=['','ДОКО',
'Руководитель учебного подразделения',
'Заместитель директора по учебной работе',
'Заведующий выпускающей кафедры',
'Секретарь ГЭК',
'Ответственный за техническое сопровождение']   

function findWithAttr(array, value) {
    for(var i = 0; i < array.length; i += 1) {
        if(parseInt(array[i]["id"]) === parseInt(value)) {
             return array[i]["name"];
        }
    }
    return false;
}


const result = findWithAttr(responsible,props.match.params.question);
function showformdata(result) {
    
    if (result!==false) {
        return (<Form.Item label="Наименование документа">
    <span>{spandata(result,1)}</span>
</Form.Item>) } else  {
return (
    <span>Ошибка</span>
)
}
}

var buttondisabled=false
var formdisabled=false


useEffect(() => {
    const valueid = parseInt(props.match.params.id, 10);
    const form = parseInt(props.match.params.form, 10);
    if ((valueid,form)) {
        getHeader(valueid,form);
    }

// eslint-disable-next-line     
}, [props.match.params.id,props.match.params.form]);

const getHeader = (valueid,form) => {
    API_OOPGet(valueid,form).then(response => {
        setrole(response[0].role); // ставим роль   
        setfac(response[0].fak); // УЧП
        setcode(response[0].code); //code
          setPageName(`${response[0].fgos},${response[0].code},${response[0].fak} ${response[0].napr} ${response[0].profile}, ${response[0].form} форма`)
        
    }).catch(function(error) {
        
        try {
            setPageName(`Ошибка доступа`)
            notification.error({ message: `Ошибка 1`, description: 'У вас нет доступа или раздел отсутствует' });
            buttondisabled=true
            formdisabled=true
        } catch {
            notification.error({ message: `Ошибка 2`, description: 'У вас нет доступа или раздел отсутствует' });
            setPageName(`Ошибка доступа`)
            buttondisabled=true
            formdisabled=true
            setPageName(`У вас нет доступа или раздел отсутствует`)
            
        }
    });;
    
};




    useEffect(() => {
        const valueid = parseInt(props.match.params.id, 10);
        const form = parseInt(props.match.params.form, 10);
        const question= parseInt(props.match.params.question, 10);
        if ((valueid,form,question)) {
            getFiles();
            setformid(form);
            setvalueid(valueid);
        }
         // eslint-disable-next-line
    }, [props.match.params.id,props.match.params.form]);



    const  onSearch = search => {
        if (search.length>3) {
        var datacopy=[];
         API_SearchPerson(search,fac,code,props.match.params.form).then(response => {
            for (let i=0; i<Object.size(response); i++) {
                datacopy[i]=response[i].fio +"|"+response[i].parus_id;
            }
              setdataSource(datacopy)      
        })
    }
    };

    
    const getFiles = () => {
        const valueid = parseInt(props.match.params.id, 10);
        const form = parseInt(props.match.params.form, 10);
        const question= parseInt(props.match.params.question, 10);
        API_getFiles(valueid,form,question).then(response => {
            if (response.data) {
                
                setPeopleFiles(response.data); // сюда должны
            }
        });
    };
    const SetUrl=(choicetype,comment)=> {
        if ((choicetype==="directum")&&(comment>0)) {
        return  <a target="_blank"  rel="noopener noreferrer" href={"https://directum.s-vfu.ru/Doc.aspx?sys=DIRECTUM&ext=BaseWebAccess&ID="+comment+"&view=Preview&VersionNum=lastver"}>
        {spandata("Ссылка на Directum",11)}
        </a>
        }
        
    }
    const showform=(choicetype) => {
        //<p style={{ fontSize: 14, marginBottom: 10, marginTop: 10}}>при загрузке файла -<b> "файл"</b>; при условии того, что размещаемый файл будет повторять уже размещенный файл, необходимо выбрать <b>"ссылка"</b> и в окно "описание документа" поставить номер файла, например <b>30</b>. Данное обозначение служит ссылкой на уже размещенный в системе файл.  ссылка на Directum, находите номер документа ИД и ставите сюда ссылка в итоге будет выглядеть так https://directum.s-vfu.ru/Doc.aspx?sys=DIRECTUM&ext=BaseWebAccess&ID=номер&view=Preview&VersionNum=lastver</p>
        switch(choicetype) {
            case "url":
                return (
                    <Form.Item label="Вводите только число - ID существующего файла ранее загруженнего в модуль"  style={{ marginBottom: 5 }} disabled={formdisabled}>
                    <Input required value={comment} onChange={e => setComment(e.target.value)} style={{ width: 200 }} placeholder="Введите ссылку" />
                </Form.Item>
                )
            case "directum":
                return (
                 <Form.Item label="Вводите только число, также загружаете документ"  style={{ marginBottom: 5 }} disabled={formdisabled}>
                      Находите номер документа ИД внутри  системы Directum и ставите сюда ссылка в итоге будет выглядеть так https://directum.s-vfu.ru/Doc.aspx?sys=DIRECTUM&ext=BaseWebAccess&ID=номер&view=Preview&VersionNum=lastver. Это будет удобно если вам нужен будет срочно документ в directum и вы можете его быстро найти: 
                     <Input required value={comment} onChange={e => setComment(e.target.value)} style={{ width: 400 }} placeholder="Введите ИД Документа из DIRECTUM" />
                </Form.Item>
                )
            case "student2":
                    return (
                    <Form.Item style={{ marginBottom: 5 }}>
                        Внимание если студент не найден, это означает что он или уже отчислился или же у него стоит неверный год окончания, в этом случае проверяете ИИСУСС, преимущество данного метода в том что вы можете одним кликом увидеть все привязанные документы к студенту с всех разделов - также можно это будет привязать в личный кабинет студента
                       <p><AutoComplete  onSelect={setComment} dataSource={PersondataSource}  style={{ width: 600 }}  onSearch={onSearch}   placeholder="Введите Фамилию" /></p>
                       <p>Вводите только число - ID существующего файла внутри системы ранее загруженнего в модуль</p>
                       <Input required value={urldoc} onChange={e => seturldoc(e.target.value)} style={{ width: 200 }} placeholder="Введите ID" />
                    </Form.Item>
                    )    
            case "student":
                return (
                <Form.Item style={{ marginBottom: 5 }}>
                    Внимание если студент не найден, это означает что он или уже отчислился или же у него стоит неверный год окончания, в этом случае проверяете ИИСУСС, преимущество данного метода в том что вы можете одним кликом увидеть все привязанные документы к студенту с всех разделов - также можно это будет привязать в личный кабинет студента
                   <AutoComplete  onSelect={setComment} dataSource={PersondataSource}  style={{ width: 600 }}  onSearch={onSearch}   placeholder="Введите Фамилию" />
                </Form.Item>
                )
            default:
                return (
                    <Form.Item label="Описание документа"  style={{ marginBottom: 5 }} disabled={formdisabled}>
                        Вводите подробное описание документа чтобы было потом быстро найти
                    <p><TextArea required value={comment} onChange={e => setComment(e.target.value)} style={{ width: 600 }} placeholder="Введите подробное описание документа не более 1000 символов" /></p>
                </Form.Item>
                )
        }
    }
    // при доооавлениии ссылки
    const AddUrl = (urldoc)=> {
        let commentid=comment.replace(/[^0-9]+/g, '');
        if (commentid==="") {
            notification.error({ message: `Ошибка`, description: 'ссылка должно быть число, операция прервана' });
        } else {
        const json = {
            comment: commentid,
            idquestion: parseInt(props.match.params.question,10),
            idvalue: parseInt(props.match.params.id,10),
            form: parseInt(props.match.params.form,10),
            choicetype : urldoc
            
        };
        API_addURL(json)
                .then(response => {
                    setFileList([]);
                    getFiles();
                    if (response.data.IDFile===0) {
                        notification.error({ message: `Ошибка`, description: 'Ссылка не существует или файл удален' });

                    } else {
                        notification.success({ message: `Ссылка добавлена` });

                    }

                })
                .catch(error => {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                    }
                })
            }
    }


    // загрузка файла
    const uploadFile = (file,choicetype) => {
        var reader = new FileReader();
        setLoading(true);
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader.result.length>128000000) {
                notification.error({ message: `Ошибка`, description: 'Слишком большой файл '+Math.round(reader.result.length/1.5/(1024*1024))+ ' МБ '  }); 
                setLoading(false);
                return
            }
            let fileSource = reader.result.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');
            const json = {
                comment: comment,
                idquestion: parseInt(props.match.params.question,10),
                idvalue: parseInt(props.match.params.id,10),
                form: parseInt(props.match.params.form,10),
                fileName: file.name,
                fileBody: fileSource,
                choicetype: choicetype
            };
            
            API_uploadFile(json)
                .then(response => {
                    setFile(undefined);
                    setFileList([]);
                    getFiles();
                    if (response.data.IDFile==="0")
                    notification.error({ message: `Ошибка`, description: 'У вас нету доступа на загрузку сюда файлов' });
                    else
                    notification.success({ message: `Файл загружен` });
                })
                .catch(error => {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                    }
                })
                .finally(() => setLoading(false));
        };
    };

    const deleteFile = idFile => {
        Modal.confirm({
            title: 'Вы уверены?',
            centered: true,
            onOk: () => {
                setLoading(true);
                API_deleteFile(idFile)
                    .then(response => {
                        
                        getFiles();
                        switch(response) {
                        case 'done':  notification.success({ message: `Файл удален` }); break;
                        case 'url': notification.warning({ message: `Ошибка`, description: 'Нельзя удалять файл, где есть ссылка' }); break;
                        case 'done url':  notification.success({ message: `ссылка удалена` }); break;
                        default: notification.success({ message: 'файл удален' }); break;
                        }
                    })
                    .catch(error => {
                        try {
                            notification.error({ message: `Ошибка`, description: 'У вас нету доступа' });
                            //notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                    })
                    .finally(() => setLoading(false));
            },
        });
    };

    const downloadFile = (idFile,namefile) => {
        setLoading(true);
        API_downloadFile(idFile,namefile)
            .then(response => {
                notification.info({ message: `Файл загружается: `});
            })
            .catch(error => {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
            })
            .finally(() => setLoading(false));
    };

    const docColumns = [
        {
            title: '№',
            dataIndex: 'rowNumber',
            key: 'id',
        },
        {
            title: 'Имя файла',
            key: 'docname',
            ...getColumnSearchProps('docname','Имя '),
            render: row => {
                
                if (row.id_directum>0) {
                    return  <a target="_blank"  rel="noopener noreferrer" href={"https://directum.s-vfu.ru/Doc.aspx?sys=DIRECTUM&ext=BaseWebAccess&ID="+row.id_directum+"&view=Preview&VersionNum=lastver"}>
        {spandata("Ссылка на Directum",11)}
        </a>
                } else {
                    return spandata(row.docname,12)
                }
            }
        },
        { title: 'Описание', key: 'comment',
            ...getColumnSearchProps('comment','Описание'),
            sorter: (a, b) => compareByAlph(a.comment, b.comment),
            render: row => {
          if (row.id>0) {
            if   (row.url===undefined)  {
                if (row.id_peoplepln>0) {
                    return <Link 
                        to={{
                          pathname: `/studdocs/${row.id_peoplepln}` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
                        }}>
                        {spandata(row.comment +"|"+row.id,11)}
                        </Link>
                } else {
                return spandata(row.comment+"|"+row.id,12) 
                }
            }
            else  {
                if (row.id_peoplepln>0) {
                    return <Link 
                        to={{
                          pathname: `/studdocs/${row.id_peoplepln}` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
                        }}>
                        {spandata(row.comment +"|"+row.id,11)}
                        </Link>
                } else {
                return spandata(row.comment+' (ссылка на файл ) '+row.url) 
                }
            }
            }
        }
        },
        {
            title: 'Дата загрузки',
            key: 'dateupload',
            dataIndex: 'dateupload',
            sorter: (a, b) => moment(a.dateupload).unix() - moment(b.dateupload).unix()
        },
        {
            title: 'Скачать',
            align: 'center',
            width: 120,
            render: row => {
                return <Button icon="download" onClick={() => downloadFile(row.id,row.docname)} loading={loading} />;
            },
        },
        {
            title: 'Удалить',
            align: 'center',
            width: 120,
            render: row => <Button icon="delete" type="danger" loading={loading} onClick={() => deleteFile(row.id)} />,
        },
    ];
    let index=0
    const docData = () =>
        peopleFiles.map(s => {
            index++;
            return {
                ...s,
                rowNumber: index,
            };
        });
const  routes = [{ label: 'Главная', path: '/' }, { label: 'ОПОП', path: '/task' },{label: 'Вернуться в перечень', path: '/tasksingle/'+valueid+'/'+formid}]        
if ((pageName!=='Ошибка доступа'))  {
    
    return (
        <>
        Ваша роль - {spandata(roles[role],11,role)}
        <PageBreadcrumbs routes={routes} />
        
            <PageTitle title={pageName} />
            
            <p style={{ fontSize: 18, marginBottom: 16, marginTop: 20 }}>Загрузка документов</p>
            
            <Form id="20">
            {showformdata(result)}
           

            <Form.Item label="Определите тип:">
                <Select onChange={choicetypevalue} style={{ width: 600 }}>
                    <Option value="file">Файл</Option>
                    <Option value="url">ссылка внутри системы</Option>
                    <Option value="directum">ссылка на Directum</Option>
                    <Option value="student">Привязать студента к файлу заявлениям</Option>
                    <Option value="student2">Привязать студента к приказу,протоколу</Option>
                </Select>
                </Form.Item>
                {showform(choicetype)}    
                
                <Form.Item label="Файл" required>
                    <Upload
                        accept=".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx,.zip,.rar"
                        fileList={fileList}
                        onChange={info => {
                            let fileList = [...info.fileList];
                            fileList = fileList.slice(-1);
                            setFileList(fileList)
                        }}
                       // disabled = {!result}
                       disabled = {((choicetype==="file" ||choicetype==="directum" || choicetype==="student") && comment!=="") ? false: true}
                        beforeUpload={file => {
                            setFile(file);
                            return false;
                        }}
                    >
                        <Button icon="plus" type="primary" disabled={buttondisabled} 
                        >
                            Выбрать файл
                        </Button><p>
                        {(choicetype==="file" ||choicetype==="directum" || choicetype==="student") ? spandata("Загрузить (до 90 Мегабайт = 90*1024*1024 байт)",10):"Загрузка отключается при выборе ввода ссылки на файл внутри системы"}
                        </p>
                    </Upload>
                </Form.Item>
                <Form.Item>
                {SetUrl(choicetype,comment)}
                    <Button id="uploadme" loading={loading}
                        onClick={() => (role!==6 && comment!=="" && file && (choicetype==="file" ||choicetype==="directum" || choicetype==="student")) ? uploadFile(file,choicetype) :  AddUrl((urldoc===undefined)?'0':urldoc)}
                        type="primary"
                        disabled={(role!==6 && comment!=="" && file && (choicetype==="file" ||choicetype==="directum" || choicetype==="student") ) ? false : ((!file && (comment!=="" && choicetype==="url"))  || (!file && (comment!=="" && urldoc!=="" && choicetype==="student2")) ) ? false: true}
                        style={{ width: 200, display: 'block', margin: '25px auto 0 auto' }}
                        
                    >
                    Сохранить
                    </Button>
                </Form.Item>
            </Form>
            
            <Table  rowKey ={record => record.id} 
            pagination={{
                pageSizeOptions: ["50","100"],
                showSizeChanger: true,
              }}
             columns={docColumns} dataSource={docData()} bordered size="small" />
        </>
    );

} else {
return (
        
    <>
    Ваша роль - {spandata(roles[role],11,role)}
        <PageBreadcrumbs routes={routes} />
        <PageTitle title={pageName} />
            
    </>
)
}
};
export default AccredDocUpload;
