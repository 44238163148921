import React from 'react'
import {Link } from 'react-router-dom';
//import {SearchInput} from 'react-search-input';
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { useEffect, useState } from 'react';
import { API_getListFilesOne } from '../api/file';
import { Table} from 'antd';
import {spandata} from './../api/addon';
import {compareByAlph} from './../api/addon';
import getColumnSearchProps from './../components/getColumnSearch'

var moment = require('moment');

const routes = [{ label: 'Главная', path: '/' }, { label: 'Список файлов', path: '/files' }]

const FileListDocsOne = (props) => {
    
    const [filelist, setfilelist] = useState([]);
 

  const columns = [
    {
        title: '№',
        dataIndex: 'rowNumber',
        key: 'rowNumber',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.rowNumber - b.rowNumber,
    },
    {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.id - b.id,
    },
    {
        title: 'Шифр',
        dataIndex: 'apicode',
        key: 'apicode',
        sorter: (a, b) => compareByAlph(a.apicode, b.apicode),
        ...getColumnSearchProps('apicode','Шифр'),
    },
    {
        title: 'Информация',
        dataIndex: 'comment',
        key: 'comment',
        sorter: (a, b) => compareByAlph(a.comment, b.comment),
        ...getColumnSearchProps('comment','Комментарий'),
    },
    {
        title: 'Раздел',
        dataIndex: 'fieldsorted',
        key: 'fieldsorted',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.id - b.id,

    },
    {
        title: 'Имя файла',
        key: 'docname',
        ...getColumnSearchProps('docname','Имя файла'),
        render: row=> {
            var noshow = true
            if (row.apicode==='_Проблема') { noshow=false}
            if (row.value_id===undefined) { noshow=false }
            if (noshow) {
            return <Link 
            to={{
              pathname: `/setdocument/${row.value_id}/${row.form}/${row.question_id}` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
            }}>
            {spandata(row.docname,10)}
            </Link>
            } else {
                return spandata(row.docname)
            
            }
        },
       
    },
    {
        title: 'Изменено',
        dataIndex: 'dateupload',
        key: 'dateupload',
        sorter: (a, b) => moment(a.dateupload).unix() - moment(b.dateupload).unix()
    },
    {
        title: 'Наименование раздела',
        dataIndex: 'qname',
        key: 'qname',
        ...getColumnSearchProps('qname','название раздела'),
    }
    
];

    useEffect(() => {
        const id_parus = parseInt(props.match.params.id, 10);        
        API_getListFilesOne(id_parus).then(responce => {
            let index = 0;
            setfilelist(
                responce.map(s => {
                    index++;
                    return {
                        ...s,
                        rowNumber: index,
                    };
                })
            );
        });
    }, [props.match.params.id]);

        
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <PageTitle title="Загруженные пользователем документы" />
            <div>Отсортируйте по Шифру или сделайте поиск по слову Проблема, означает что вы указали неверную ссылку на недоступный вам раздел - загрузите повторно документ или укажите верную ссылку и напишите мне чтобы я удалил ее</div>
            <Table columns={columns} dataSource={filelist} bordered size="small" 
            pagination={{
                pageSizeOptions: ["50","100","1000"],
                showSizeChanger: true,
              }}
                rowKey ={record => record.id} 
                />
        </>
    )
}

export default FileListDocsOne
