import React from 'react'

export const spandata=(text,style,role=0)=> {
    let adddata='';
    const styleint=parseInt(style, 10);
	switch(role) {
		case 6:
		adddata='БЕЗ ДОСТУПА НА ВНЕСЕНИЕ КОРРЕКТИРОВОК';
		break;
		default:
		adddata='';
		break;
	}
    switch(styleint) {
    case 1:  return <div  style={{ fontSize: "15px", fontWeight: "bold", "backgroundColor": "#2BB145"}}>{text}</div>
    case 2:  return <span  style={{ fontSize: "13px", fontStyle: "italic"}}>{text}</span>
    case 4: 
    text=text.replace('документ отменен, не заполнять','');
    return <p><span  style={{ fontSize: "13px", "textDecoration": "line-through"}}>{text}</span><span  style={{ fontSize: "13px"}}>документ отменен, не заполнять</span></p>
    case 10:  return <span  style={{ fontSize: "14px", fontStyle: "italic", color: "blue"}}>{text}</span>
    case 11:  return <span  style={{ fontSize: "14px", fontWeight: "bold", color: "blue"}}>{text} {adddata}</span>
    case 12:  
    var texts=text.split("|")
    return <span  style={{ fontSize: "12px"}}>{texts[0]} <span  style={{ fontSize: "15px", fontWeight: "bold", color: "green"}}>- {texts[1]}</span></span>
    case 3: return <a href="/localact" className="blue">{text}</a>
    default:
    return <span>{text}</span>
    }
}
export const  compareByAlph = (a, b) => { if (a > b) { return -1; } if (a < b) { return 1; } return 0; }
export const PageTitle2 = props => <p style={{ fontSize: 16, margin: '5px 0 10px 0' }}><b>{props.title}</b></p>


