import React from 'react'
import { notification } from 'antd';
import { useEffect } from 'react';
import { API_Online } from '../api/getheader';


const LocalAct = (props) => {
    const getOnline = () => {
        API_Online().then(response => {
            if (parseInt(response.Status)===0) {
                notification.error({ message: `Ваша сессия истекла`, description: "Нужно перезайти" });
                localStorage.removeItem('accessToken')
                props.history.push('/login')
                // время истекло должно выкинуть
            }
            if (parseInt(response.Status)===2) {
                notification.error({ message: `Авторизация успешна, но доступ закрыт`, description: "Ваши данные не найдены в рабочих группах модуля \"Государственная итоговая аттестация\" , доступ имеют секретарри,техподдержка,зав.кфедры,зам.деканы по уч.работе, руководители подразделений" });
                localStorage.removeItem('accessToken')
                props.history.push('/login')
                // время истекло должно выкинуть
            }
        }).catch(function(error) {
            try {
                notification.error({ message: `Ошибка 1`, description: "неопознанная ошибка" });
                
            } catch {
                notification.error({ message: `Ошибка 2`, description: "неопознанная ошибка" });
            }
        });;
    };
    useEffect(() => {
            getOnline();
    // eslint-disable-next-line            
    }, []);



    return (
        <>
                <div class="resp-container">
                <iframe title="Справка" class="resp-iframe" src="https://docs.google.com/document/d/e/2PACX-1vQ0Rg7r7hK6plGhEeVCxPKfhIHkA6viJvqtoGEDaO2Af6ZjzQ4_WSfTVGnGbEFs7xsjVjMo8yKeYjVT/pub"  gesture="media"  allow="encrypted-media" allowfullscreen></iframe>
                </div>
        </>
    )
}

export default LocalAct
