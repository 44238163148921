import axios from './../utils/axios';
import { notification } from 'antd';
export const API_TemplatedownloadFile2 = (folder,lvl,idFile) => {
    return axios({
        method: 'GET',
        url: `templates/checklist/${folder}/${lvl}/${idFile}`,
        responseType: 'blob',
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `checklistfile-${idFile}.docx`);
        document.body.appendChild(link);
        link.click();
    });
};

export const API_TemplatedownloadFile = (folder,lvl,idFile,filename) => {
    return axios({
        method: 'GET',
        url: `templates/download/${folder}/${lvl}/${idFile}`,
        responseType: 'blob',
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        console.log('параметр 3'+filename);
        link.setAttribute('download',filename);
        /*
        
        var excel=false;
        if (idFile==="17") { 
            excel=true;
        }
        if (!excel) {
        link.setAttribute('download', `templatefile-${idFile}.docx`);
        } else {
        link.setAttribute('download', `templatefile-${idFile}.xlsx`);
        }*/
        document.body.appendChild(link);
        link.click();
    });
};
// может быть несколько файлов для разделов и вопросов
export const API_getListFiles = () => {
    return axios({
        method: 'GET',
        url: `files`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};



// может быть несколько файлов для разделов и вопросов
export const API_getListFilesOne = (id_parus) => {
    return axios({
        method: 'GET',
        url: `files/${id_parus}`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

// может быть несколько файлов для разделов и вопросов
export const API_getListFilesOneStud = (id_peoplepln) => {
    return axios({
        method: 'GET',
        url: `filesstud/${id_peoplepln}`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};


// может быть несколько файлов для разделов и вопросов
//valueid,form,question
export const API_getFiles = (valueid,form,question,desc) => {
    return axios({
        method: 'GET',
        url: `file/list/${valueid}/${form}/${question}`,
    });
};

export const API_getExpertFiles = (parentid) => {
    return axios({
        method: 'GET',
        url: `file/expertlist/${parentid}`,
    });
};


//API_uploadFileTemplate

export const API_uploadFileTemplate = json => {
//    const url = json.idFile ? `templates/update/${json.idFile}` : `templates/upload`;
    
    return axios({
//        method: json.idFile ? 'PUT' : 'POST',
        method: 'PUT',
        url: `templates/upload`,
        data: {
            idquestion: json.idquestion,
            fileName: json.fileName,
            fgos: json.fgos,
            fileBody: json.fileBody,
            lvl: json.lvl
        },
    });
};

export const API_deleteFile = idFile => {
    return axios({
        method: 'DELETE',
        url: `file/${idFile}`,
    }).then(function(response) {
        console.log(response.data);
        return response.data;
    });
};
export const API_addURL= json => {
    const url = `url/upload`;
    return axios({
        method: json.idFile ? 'PUT' : 'POST',
        url: url,
        data: {
            comment: json.comment,
            idquestion: json.idquestion,
            idvalue: json.idvalue,
            form: json.form,
            choicetype : json.choicetype
        },
    });
}


export const API_uploadFile = json => {
    const url = `file/upload`;
    return axios({
        method: json.idFile ? 'PUT' : 'POST',
        url: url,
        data: {
            comment: json.comment,
            idquestion: json.idquestion,
            idvalue: json.idvalue,
            form: json.form,
            fileName: json.fileName,
            fileBody: json.fileBody,
            choicetype: json.choicetype
        },
    });
};

export const API_downloadFile = (idFile,namefile) => {
    return axios({
        method: 'GET',
        url: `file/download/${idFile}`,
        responseType: 'blob',
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', namefile);
        document.body.appendChild(link);
        link.click();
    });
};


export const API_GetAboutFile = (id) => {
    return axios({
        method: 'GET',
        url: `file/${id}`,
    });
};

export const API_GetTemplateName = (id) => {
    return axios({
        method: 'GET',
        url: `templates/${id}`,
    });
};

export const API_SearchPerson = (search,fac,code,form) => {
    return axios({
        method: 'get',
        url: `getperson/?search=${search}&fac=${fac}&code=${code}&form=${form}`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};
