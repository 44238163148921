import React from 'react';
import { Menu, Icon, Layout,Popover } from 'antd';
import Header from './main/Header';
import { withRouter } from 'react-router-dom';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Users from '../pages/Users';
import Footer from './main/Footer';
import Statementproperty from './../pages/Statementproperty';
import Responsible from './../pages/Responsible';
import TaskOOP from './../pages/Task';
import TaskSingle from './../pages/Tasksingle';
import GetDocument from './../pages/GetDocument';
import GetQuestion from '../pages/GetQuestion';
import FileListDocsOneStud from '../pages/FileListOneStud';
import AccredDocUpload from '../components/AccredDocUpload';
import FileListDocs from './../pages/FileList';
import FileListDocsOne from './../pages/FileListOne';
import StatOOP from './../pages/Stat';
import Instruction from '../pages/Help';
import LocalAct from '../pages/Doko';
function menuadmin(pathname) {
    const content = (
        <div>
            Основные профессиональные образовательные программы
        </div>
      );
      
    if (localStorage.getItem('login')==='admin') {
    return (
        <Menu
        mode="horizontal"
        style={{ height: '100%' }}
        activeKey={pathname}
        selectedKeys={[pathname]}
    >
        <Menu.Item key="/task">
        <Popover content={content} title="Справка">
            <NavLink to="/task/">
                <Icon type="exception" /> <span>ОПОП</span>
            </NavLink>
        </Popover>
        </Menu.Item>
        <Menu.Item key="/aboutgroup">
            <NavLink to="/aboutgroup/0/1/1">
                <Icon type="gateway" /> <span>Состав участников ГИА</span>
            </NavLink>
        </Menu.Item>
        <Menu.Item key="/users">
            <NavLink to="/users">
                <Icon type="radar-chart" /> <span>Активность</span>
            </NavLink>
        </Menu.Item>
        <Menu.Item key="/docs">
        <NavLink to="/docs">
            <Icon type="folder-open" /> <span>Мои документы</span>
        </NavLink>
        </Menu.Item>
        <Menu.Item key="/help">
        <NavLink to="/help">
            <Icon type="question" /> <span>Инструкция</span>
        </NavLink>
        </Menu.Item>
        <Menu.Item key="/news">
        <NavLink to="/news">
        <Icon type="snippets" /><span>Новости</span>
        </NavLink>
    </Menu.Item>
    <Menu.Item key="/stat">
        <NavLink to="/stat">
             <span>Статистика</span>
        </NavLink>
        </Menu.Item>
    </Menu>

    ) } else  {
    return (
        <Menu
        mode="horizontal"
        style={{ height: '100%' }}
        activeKey={pathname}
        selectedKeys={[pathname]}
    >
        <Menu.Item key="/task">
        <NavLink to="/task/">
            <Icon type="exception" /> <span>ОПОП</span>
        </NavLink>
    </Menu.Item>
    <Menu.Item key="/aboutgroup">
            <NavLink to="/aboutgroup/0/1/1">
                <Icon type="gateway" /> <span>Состав участников ГИА</span>
            </NavLink>
    </Menu.Item>
    <Menu.Item key="/users">
        <NavLink to="/users">
            <Icon type="radar-chart" /> <span>Активность</span>
        </NavLink>
    </Menu.Item>
    <Menu.Item key="/docs">
        <NavLink to="/docs">
            <Icon type="folder-open" /> <span>Мои документы</span>
        </NavLink>
    </Menu.Item>
    <Menu.Item key="/help">
        <NavLink to="/help">
            <Icon type="question" /> <span>Инструкция</span>
        </NavLink>
    </Menu.Item>
    <Menu.Item key="/news">
        <NavLink to="/news">
        <Icon type="snippets" /> <span>Новости</span>
        </NavLink>
    </Menu.Item>
        </Menu>
    )
    }
}
const Main = props => {
    return (
        <div className="wrapper">
            <div className="content">
                <Header history={props.history} />
                <Layout>
                    {menuadmin(props.history.location.pathname)}
                    <Layout style={{ padding: '0 24px 24px', background: '#FFF' }}>
                        <Route exact path="/" render={() => <Redirect to="/task" />} />
                        <Switch>
                            <Route path="/statementproperty" component={Statementproperty} />
                            
                            <Route path="/aboutgroup/:id/:form" component={Responsible} />
                            <Route path="/task/:id/:fak" component={TaskOOP} />
                            <Route path="/task/:id" component={TaskOOP} />
                            <Route path="/task" component={TaskOOP} />
                            <Route path="/docs/:id" component={FileListDocsOne} />
                            <Route path="/studdocs/:id" component={FileListDocsOneStud} />
                            <Route path="/docs" component={FileListDocs} />
                            
                            <Route path="/tasksingle/:id/:form" component={TaskSingle} />
                           <Route path="/getdocument/:id/:uid" component={GetDocument} />
                           <Route path="/setdocument/:id/:form/:question" component={AccredDocUpload} />
                           <Route path="/docforstatement/:lvl" component={GetQuestion} />
                           <Route path="/users" component={Users} />
                           <Route path="/stat/:id" component={StatOOP} />
                           <Route path="/stat" component={StatOOP} />
                           <Route path="/help" component={Instruction} />
                           <Route path="/news" component={LocalAct} />
                        </Switch>
                    </Layout>
                </Layout>
            </div>
            <Footer />
        </div>
    );
};
export default withRouter(props => <Main {...props} />);
