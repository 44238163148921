import React from 'react'

const Footer = () => (
    <div>
    <div style={{ height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', 
    background: ' linear-gradient(90deg, rgba(16,87,14,1) 18%, rgba(116,204,119,1) 100%)', flex: '0 0 auto', color: '#FFF' }}>
        <p>ФГАОУ ВО Северо-Восточный федеральный университет им. М.К. Аммосова © 2023, Департамент по обеспечению качества образования при поддержке Департамента цифровых технологий</p>
        
    </div>
    <p><b>В случае нахождения проблем с функционалом пишем на почту ss.mikhailov@s-vfu.ru или по комфорту Администратор</b></p>
    </div>
)

export default Footer
