import axios from './../utils/axios';
import React from 'react';
import { NavLink } from 'react-router-dom';
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { useEffect, useState } from 'react';
import { API_OOPGet } from '../api/oop';
import { API_GetDocument } from '../api/getdocument';
import { Table, Menu, Button, notification } from 'antd';
import { API_downloadFile } from './../api/file';
import { spandata, PageTitle2 } from './../api/addon';
import { API_Online } from '../api/getheader';
import getColumnSearchProps from './../components/getColumnSearch';
const routes = [
    { label: 'Главная', path: '/' },
    { label: 'ООП', path: '/task' },
];
//const { Option } = Select;

const TaskOOP = (props) => {
    const getOnline = () => {
        API_Online()
            .then((response) => {
                if (parseInt(response.Status) === 0) {
                    notification.error({ message: `Ваша сессия истекла`, description: 'Нужно перезайти' });
                    localStorage.removeItem('accessToken');
                    props.history.push('/login');
                    // время истекло должно выкинуть
                }
                if (parseInt(response.Status) === 2) {
                    notification.error({
                        message: `Авторизация успешна, но доступ закрыт`,
                        description:
                            'Ваши данные не найдены в рабочих группах модуля "Государственная итоговая аттестация" , доступ имеют секретарри,техподдержка,зав.кфедры,зам.деканы по уч.работе, руководители подразделений',
                    });
                    localStorage.removeItem('accessToken');
                    props.history.push('/login');
                    // время истекло должно выкинуть
                }
            })
            .catch(function (error) {
                try {
                    notification.error({ message: `Ошибка 1`, description: 'неопознанная ошибка' });
                } catch {
                    notification.error({ message: `Ошибка 2`, description: 'неопознанная ошибка' });
                }
            });
    };
    useEffect(() => {
        getOnline();
        // eslint-disable-next-line
    }, []);
    const [loading, setLoading] = useState(false);
    const [pageName, setPageName] = useState('Загрузка документов');
    const [data, GetDocument] = useState([]);
    const [role, setrole] = useState(0);
    // Основная штука которая определяет доступ к разделам по авторизации

    useEffect(() => {
        const valueid = parseInt(props.match.params.id, 10);
        const form = parseInt(props.match.params.form, 10);
        if ((valueid, form)) {
            getHeader(valueid, form);
        }
    }, [props.match.params.id, props.match.params.form]);

    function getHeader(valueid, form) {
        API_OOPGet(valueid, form)
            .then((response) => {
                if (response[0].fgos === undefined) {
                    setPageName(`Ошибка доступа`);
                    notification.error({ message: `У вас нет доступа`, description: `В системе показывает только разделы где есть набор` });
                } else {
                    setrole(response[0].role); // ставим роль
                    setPageName(`${response[0].code},${response[0].fak} ${response[0].napr} ${response[0].profile} ${response[0].form} форма`);
                }
            })
            .catch((error) => {
                try {
                    setPageName(`Ошибка доступа`);
                    notification.error({ message: `У вас нет доступа или раздел отсутствует`, description: error.response });
                } catch {}
            });
    }
    const roles = [
        '',
        'ДОКО',
        'Руководитель учебного подразделения',
        'Заместитель директора по учебной работе',
        'Заведующий выпускающей кафедры',
        'Секретарь ГЭК',
        'Ответственный за техническое сопровождение',
    ];

    //   const [filename, setfilename] = useState(false);
    // загрузка шаблона - по идее еще должна быть загрузка самого файла
    /*
const downloadTemplateFile = (folder,lvl,idFile) => {
        axios({
            method: 'GET',
            url: `templates/${idFile}`,
        }).then(response => {
            var filename=response.data.fileName;
            return axios({
            method: 'GET',
            url: `templates/download/${folder}/${lvl}/${idFile}`,
            responseType: 'blob',
            
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',filename);{}
            document.body.appendChild(link);
            link.click();
        });
    });

};
*/
    //   const [filename, setfilename] = useState(false);
    // загрузка шаблона - по идее еще должна быть загрузка самого файла
    const downloadTemplateFile = async (folder, lvl, idFile) => {
        try {
            const response = await axios({ method: 'GET', url: `templates/${idFile}` });
            var filename = response.data.fileName;
            const response2 = await axios({
                method: 'GET',
                url: `templates/download/${folder}/${lvl}/${idFile}`,
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response2.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            notification.error({ message: `Ошибка`, description: 'неопознанная ошибка' });
        }
    };

    const downloadFile = (idFile, namefile) => {
        setLoading(true);
        API_downloadFile(idFile, namefile)
            .then((response) => {
                notification.info({ message: `Файл загружается: ` });
            })
            .catch((error) => {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
            })
            .finally(() => setLoading(false));
    };
    const checkdirlink = (value) => {
        if (value !== '0') {
            var arrayOfStrings = value.split('|');
            if (arrayOfStrings[0] === 'D')
                return (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={'https://directum.s-vfu.ru/Doc.aspx?sys=DIRECTUM&ext=BaseWebAccess&ID=' + arrayOfStrings[1] + '&view=Preview&VersionNum=lastver'}
                    >
                        {spandata('Ссылка на Directum ', 11)}
                    </a>
                );
            else
                return (
                    <NavLink
                        to={{
                            pathname: `/studdocs/${arrayOfStrings[1]}`, // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
                        }}
                    >
                        {spandata('Подробнее ', 11)}
                    </NavLink>
                );
        }
    };
    const columns = [
        {
            title: 'Наименование документа',
            ...getColumnSearchProps('qname', 'Наименование'),
            render: (row) => {
                return spandata(row.qname, row.design);
            },
            key: 'qname',
        },
        {
            title: 'Форма, шаблон',
            key: 'id',
            render: (row) => {
                if (row.code > 0)
                    return (
                        <Button icon="download" onClick={() => downloadTemplateFile(row.folder, parseInt(row.lvl, 10), row.templatename)} loading={loading}>
                            Шаблон
                        </Button>
                    );
                if (row.doc > 0)
                    return (
                        <Button icon="download" onClick={() => downloadTemplateFile(row.folder, parseInt(row.lvl, 10), row.doc)} loading={loading}>
                            Документ
                        </Button>
                    );
            },
        },
        {
            title: 'Загрузить документ',
            key: 'docname',
            render: (row) => {
                if (role > 0 && row.userdoc > 0) {
                    return <Button icon="upload" onClick={() => props.history.push(`/setdocument/${valueid}/${form}/${row.id}`)} type="primary" />;
                }
            },
        },
        //  в случае размещения ссылки, row.fileid ищет поле url и оттуда запускает значение. закачки
        {
            title: 'Скачать документ',
            key: 'fileid',
            render: (row) => {
                if (row.fileid > 0) {
                    return (
                        <p>
                            {checkdirlink(row.files)}
                            <Button icon="download" onClick={() => downloadFile(row.fileid, row.docname)} loading={loading} />
                        </p>
                    );
                }
            },
        },

        {
            title: 'Описание документа',
            key: 'comment',
            render: (row) => {
                if (row.fileid > 0) {
                    if (row.url === undefined) return spandata(row.comment + '|' + row.fileid, 12);
                    else return spandata(row.comment + ' (ссылка на файл ) ' + row.url);
                }
            },
        },
        { title: 'Дата последней загрузки', dataIndex: 'dateupload', key: 'dateupload' },
    ];

    // документы

    useEffect(() => {
        const valueid = parseInt(props.match.params.id, 10);
        const form = parseInt(props.match.params.form, 10);
        API_GetDocument(valueid, form).then((data) => {
            let index = 0;
            GetDocument(
                data.map((s) => {
                    index++;
                    return {
                        ...s,
                        rowNumber: index,
                    };
                })
            );
        });
    }, [props.match.params.id, props.match.params.form]);

    const valueid = parseInt(props.match.params.id, 10);
    const form = parseInt(props.match.params.form, 10);
    var telegram = '';
    if (role === 5 || role === 1) {
        telegram = 'канал в телеграмме для консультации от отдела ОБСО https://t.me/+VJSm78SYuK49-6A6';
    }
    if (pageName !== 'Ошибка доступа') {
        return (
            <>
                Ваша роль - {spandata(roles[role], 11)} {spandata(telegram, 11)}
                <PageBreadcrumbs routes={routes} />
                <PageTitle title="Основные профессиональные образовательные программы" />
                <Menu mode="horizontal" style={{ height: '100%' }} activeKey={props.history.location.pathname} selectedKeys={[props.history.location.pathname]}>
                    <Menu.Item key="bak">
                        <NavLink to="/task/03">Бакалавриат</NavLink>
                    </Menu.Item>

                    <Menu.Item key="/spec">
                        <NavLink to="/task/05">Специалитет</NavLink>
                    </Menu.Item>

                    <Menu.Item key="/mag">
                        <NavLink to="/task/04">Магистратура</NavLink>
                    </Menu.Item>
                    <Menu.Item key="/asp">
                        <NavLink to="/task/06">Аспирантура</NavLink>
                    </Menu.Item>
                    <Menu.Item key="/ord">
                        <NavLink to="/task/08">Ординатура</NavLink>
                    </Menu.Item>
                    <Menu.Item key="/spo">
                        <NavLink to="/task/02">СПО</NavLink>
                    </Menu.Item>
                </Menu>
                <PageTitle2 title={pageName} />
                <Table columns={columns} dataSource={data} bordered size="small" pagination={false} rowKey={(record) => record.rowNumber} />
            </>
        );
    } else {
        return (
            <>
                Ваша роль - {spandata(roles[role], 11)}
                <PageBreadcrumbs routes={routes} />
                <PageTitle title="Основные профессиональные образовательные программы" />
                <PageTitle2 title={pageName} />
            </>
        );
    }
};

export default TaskOOP;
