import React from 'react';
//import {SearchInput} from 'react-search-input';
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { useEffect, useState } from 'react';
import { API_getListFilesOneStud, API_downloadFile } from '../api/file';
import { Table, notification, Button } from 'antd';
import { spandata } from './../api/addon';
import { compareByAlph } from './../api/addon';
import getColumnSearchProps from './../components/getColumnSearch';

var moment = require('moment');

const routes = [
    { label: 'Главная', path: '/' },
    { label: 'Список файлов', path: '/files' },
];

const FileListDocsOneStud = (props) => {
    const [filelist, setfilelist] = useState([]);
    const [loading, setLoading] = useState(false);

    const downloadFile = (idFile, namefile) => {
        setLoading(true);
        API_downloadFile(idFile, namefile)
            .then((response) => {
                notification.info({ message: `Файл загружается: ` });
            })
            .catch((error) => {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
            })
            .finally(() => setLoading(false));
    };

    const columns = [
        {
            title: '№',
            dataIndex: 'rowNumber',
            key: 'rowNumber',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.rowNumber - b.rowNumber,
        },
        {
            title: 'Информация',
            dataIndex: 'comment',
            key: 'comment',
            sorter: (a, b) => compareByAlph(a.comment, b.comment),
        },
        {
            title: 'Раздел',
            dataIndex: 'fieldsorted',
            key: 'fieldsorted',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Имя файла',
            key: 'docname',
            render: (row) => {
                var noshow = true;
                if (row.apicode === '_Проблема') {
                    noshow = false;
                }
                if (row.value_id === undefined) {
                    noshow = false;
                }
                if (noshow) {
                    return (
                        <Button icon="download" onClick={() => downloadFile(row.id, row.docname)} loading={loading}>
                            {row.docname}
                        </Button>
                    );
                } else {
                    return spandata(row.docname);
                }
            },
        },
        {
            title: 'Изменено',
            dataIndex: 'dateupload',
            key: 'dateupload',
            sorter: (a, b) => moment(a.dateupload).unix() - moment(b.dateupload).unix(),
        },
        {
            title: 'Наименование раздела',
            dataIndex: 'qname',
            key: 'qname',
            ...getColumnSearchProps('qname', 'название раздела'),
        },
    ];

    useEffect(() => {
        const id_peoplepln = parseInt(props.match.params.id, 10);
        API_getListFilesOneStud(id_peoplepln).then((responce) => {
            let index = 0;
            setfilelist(
                responce.map((s) => {
                    index++;
                    return {
                        ...s,
                        rowNumber: index,
                    };
                })
            );
        });
    }, [props.match.params.id]);

    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <PageTitle title="Документы Студента" />
            <Table
                columns={columns}
                dataSource={filelist}
                bordered
                size="small"
                pagination={{
                    pageSizeOptions: ['50', '100', '1000'],
                    showSizeChanger: true,
                }}
                rowKey={(record) => record.id}
            />
        </>
    );
};

export default FileListDocsOneStud;
