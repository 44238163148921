import React from 'react';
import { withRouter} from 'react-router-dom';
import LoginForm from './../components/LoginForm';
/*
async function getstatus() {
   var url='https://accredapi.s-vfu.ru/accred/login';
   //var url='https://id.s-vfu.ru/auth/login';
    try {
    await fetch(url, {
	method: 'POST',
	headers: {'Content-Type': 'application/json;charset=utf-8',
	body: 'login: "", pwd: "", type: 0'}})
    .then((res) => {
		console.log('check'+res.status);
        if (parseInt(res.status,10)==405) {
            console.log(res.status);
            return 0;
        } else {
			alert('Внимание авторизация не работает!!')
		}
    })
    }
    catch {
    alert('Внимание авторизация не работает!!')
}
}
*/


function getbrowserversion() {

var nAgt = navigator.userAgent;
var browserName  = navigator.appName;
var fullVersion  = ''+parseFloat(navigator.appVersion); 
var majorVersion = parseInt(navigator.appVersion,10);
var nameOffset,verOffset,ix; 


// In Opera 15+, the true version is after "OPR/" 
if ((verOffset=nAgt.indexOf("OPR/"))!==-1) {
 browserName = "Opera";
 fullVersion = nAgt.substring(verOffset+4);
}
// In older Opera, the true version is after "Opera" or after "Version"
else if ((verOffset=nAgt.indexOf("Opera"))!==-1) {
 browserName = "Opera";
 fullVersion = nAgt.substring(verOffset+6);
 if ((verOffset=nAgt.indexOf("Version"))!==-1) 
   fullVersion = nAgt.substring(verOffset+8);
}
// In MSIE, the true version is after "MSIE" in userAgent
else if ((verOffset=nAgt.indexOf("MSIE"))!==-1) {
 browserName = "Microsoft Internet Explorer";
 fullVersion = nAgt.substring(verOffset+5);
}
// In Chrome, the true version is after "Chrome" 
else if ((verOffset=nAgt.indexOf("Chrome"))!==-1) {
 browserName = "Chrome";
 fullVersion = nAgt.substring(verOffset+7);
}
// In Safari, the true version is after "Safari" or after "Version" 
else if ((verOffset=nAgt.indexOf("Safari"))!==-1) {
 browserName = "Safari";
 fullVersion = nAgt.substring(verOffset+7);
 if ((verOffset=nAgt.indexOf("Version"))!==-1) 
   fullVersion = nAgt.substring(verOffset+8);
}
// In Firefox, the true version is after "Firefox" 
else if ((verOffset=nAgt.indexOf("Firefox"))!==-1) {
 browserName = "Firefox";
 fullVersion = nAgt.substring(verOffset+8);
}
// In most other browsers, "name/version" is at the end of userAgent 
else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
          (verOffset=nAgt.lastIndexOf('/')) ) 
{
 browserName = nAgt.substring(nameOffset,verOffset);
 fullVersion = nAgt.substring(verOffset+1);
 if (browserName.toLowerCase()===browserName.toUpperCase()) {
  browserName = navigator.appName;
 }
}
// trim the fullVersion string at semicolon/space if present
if ((ix=fullVersion.indexOf(";"))!==-1)
   fullVersion=fullVersion.substring(0,ix);
if ((ix=fullVersion.indexOf(" "))!==-1)
   fullVersion=fullVersion.substring(0,ix);

majorVersion = parseInt(''+fullVersion,10);
if (isNaN(majorVersion)) {
 fullVersion  = ''+parseFloat(navigator.appVersion); 
 majorVersion = parseInt(navigator.appVersion,10);
}
var result=[]
result['b']=browserName;
result['v']=majorVersion;
return result;
}

const Login = props => {
  //  getstatus();
    var versions=getbrowserversion();
    console.log(versions);
    var support=1;
    if ((versions['b']==="Chrome")&&(versions['v']<53))  support=0;
    if ((versions['b']==="Firefox")&&(versions['v']<54))  support=0;
    if ((versions['b']==="Opera")&&(versions['v']<38))  support=0;
    if (support===0)  {
    return (
        <div>ваш браузер не совместим c модулем "Государственная итоговая аттестация", используйте последние обновления </div>
    ) } else
    return (
        
        <div
            style={{
                background: 'radial-gradient(circle,#fff,#f1f3ff,#dceaff,#c0e2ff,#9ddbff)',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <div className="right-up"></div>
            <div className="right-down"></div>
            <div className="left-up"></div>
            <div className="left-down"></div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginBottom: 100,
                }}
            >
                <div className="login-logo-svfu"></div>
                <div className="header">
                <p style={{ textAlign: 'center', fontSize: '26px', fontWeight: 300 }}>Северо-Восточный федеральный университет им. М.К. Аммосова</p>
                <p style={{ textAlign: 'center', fontSize: '15px', marginBottom: '20px', fontWeight: 500 }}>Департамент по обеспечению качества образования при поддержке Департамента цифровых технологий</p>
                </div>
                <p style={{ textAlign: 'center', fontSize: '30px', marginBottom: '20px', fontWeight: 500 }}>Модуль "Государственная итоговая аттестация"</p>
                <p style={{ fontSize: '15px', marginBottom: '0px', fontWeight: 500 }}><a className="blue" href="https://www.s-vfu.ru/auth/resp.php" target="_blank" rel="noopener noreferrer">Проблемы с входом?</a></p>   
                <div
                    style={{
                        width: '365px',
                        background: 'rgba(255, 255, 255, 0.7)',
                        padding: '16px 30px 40px 30px',
                        borderRadius: '2px',
                        boxShadow: ' 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
                    }}
                >
                
                    <LoginForm history={props.history} />
                
                </div>
                
  
                <div style={{
                      height : "10px"
                    }} ></div>
                                
                 
                  
            </div>
            
            
        </div>
    
    );
};

export default withRouter(props => <Login {...props} />);
