import React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Table,Icon,notification } from 'antd';
import { useEffect, useState } from 'react';
import { API_GetActivity} from '../api/activity';
import { API_Online } from '../api/getheader';
import getColumnSearchProps from './../components/getColumnSearch'
var moment = require('moment');

const routes = [{ label: 'Главная', path: '/' }, { label: 'Пользователи', path: '/users' }]

const columns = [
    {
        title: '№',
        dataIndex: 'rowNumber',
        key: 'rowNumber',
    },
    {
        title: 'пользователь',
        dataIndex: 'loginname',
        key: 'loginname',
        ...getColumnSearchProps('loginname','Пользователь'),
    },
    {
        title: 'IP адрес',
        key: 'IpAddress',
        dataIndex: 'IpAddress',
    },
    {
        title: 'Последняя активность',
        key: 'LoginDate',
        dataIndex: 'LoginDate',
        sorter: (a, b) => moment(a.LoginDate).unix() - moment(b.LoginDate).unix()
    },
    {
        title: 'Статус',
        key: 'online',
        render: Response=> {
            if (Response.online>0)
            return (
            <Icon type="clock-circle" style={{ color: 'green' }} />
            )
            else 
            return (
            <Icon type="clock-circle" style={{ color: 'red' }} />
            )
            
        },
    },
];



const Users = (props) => {
    const getOnline = () => {
        API_Online().then(response => {
            if (parseInt(response.Status)===0) {
                notification.error({ message: `Ваша сессия истекла`, description: "Нужно перезайти" });
                localStorage.removeItem('accessToken')
                props.history.push('/login')
                // время истекло должно выкинуть
            }
            if (parseInt(response.Status)===2) {
                notification.error({ message: `Авторизация успешна, но доступ закрыт`, description: "Ваши данные не найдены в рабочих группах модуля \"Государственная итоговая аттестация\" , доступ имеют секретарри,техподдержка,зав.кфедры,зам.деканы по уч.работе, руководители подразделений" });
                localStorage.removeItem('accessToken')
                props.history.push('/login')
                // время истекло должно выкинуть
            }
        }).catch(function(error) {
            try {
                notification.error({ message: `Ошибка 1`, description: "неопознанная ошибка" });
                
            } catch {
                notification.error({ message: `Ошибка 2`, description: "неопознанная ошибка" });
            }
        });;
    };
    useEffect(() => {
            getOnline();
    // eslint-disable-next-line            
    }, []);


    const [getactivity, setactivity] = useState([]);
    
    
    
    useEffect(() => {
        
        API_GetActivity().then(data => {
            let index = 0;
            setactivity(
                data.map(s => {
                    index++;
                    return {
                        ...s,
                        rowNumber: index,
                    };
                })
            );
        });
    }, []); 
    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <PageTitle title="Пользователи" />
            <Table columns={columns} 
            dataSource={getactivity} 
            pagination={{
                pageSizeOptions: ["50","100"],
                showSizeChanger: true,
              }}        
            rowKey ={record => record.rowNumber}  
            bordered size="small" />
        </>
    )
}

export default Users
