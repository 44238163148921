import React from 'react'
import { NavLink,Link } from 'react-router-dom';
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { useEffect, useState } from 'react';
import { API_getstatoop } from '../api/task';
import { Table,Menu,Spin,notification} from 'antd';
import {spandata} from './../api/addon';
import { API_Online } from '../api/getheader';
import getColumnSearchProps from './../components/getColumnSearch'

const routes = [{ label: 'Главная', path: '/' }, { label: 'Статистика', path: '/stat' }]

const StatOOP = props  => {

    const getOnline = () => {
        API_Online().then(response => {
            if (parseInt(response.Status)===0) {
                notification.error({ message: `Ваша сессия истекла`, description: "Нужно перезайти" });
                localStorage.removeItem('accessToken')
                props.history.push('/login')
                // время истекло должно выкинуть
            }
            if (parseInt(response.Status)===2) {
                notification.error({ message: `Авторизация успешна, но доступ закрыт`, description: "Ваши данные не найдены в рабочих группах модуля \"Государственная итоговая аттестация\" , доступ имеют секретарри,техподдержка,зав.кфедры,зам.деканы по уч.работе, руководители подразделений" });
                localStorage.removeItem('accessToken')
                props.history.push('/login')
                // время истекло должно выкинуть
            }
        }).catch(function(error) {
            try {
                notification.error({ message: `Ошибка 1`, description: "неопознанная ошибка" });
                
            } catch {
                notification.error({ message: `Ошибка 2`, description: "неопознанная ошибка" });
            }
        });;
    };
    useEffect(() => {
            getOnline();
    // eslint-disable-next-line            
    }, []);

    const [loading, setLoading] = useState(false);

    
    const [taskinfo, settaskinfo] = useState([]);
    const columns = [
        {
            title: '№',
            key: 'rowNumber',
            dataIndex: 'rowNumber',
        },
        {
            title: 'УЧП',
            dataIndex: 'fak',
            key: 'fak',
            ...getColumnSearchProps('fak','УЧП'),
        },
        {
            title: 'Код',
            dataIndex: 'code',
            key: 'code',
            ...getColumnSearchProps('code','Код направления'),
        },
        {
            title: 'Направление',
            dataIndex: 'napr',
            key: 'napr',
            ...getColumnSearchProps('napr','Направление'),
        },
        {
            title: 'Профиль',
            dataIndex: 'profile',
            key: 'profile',
            ...getColumnSearchProps('profile','Профиль'),
        },
        
        {
            title: 'Очная форма',
            key: 'totalofo',
            render: taskinfo=> {
                if (taskinfo.ofo>0) {
                        return <Link 
                        to={{
                          pathname: `/tasksingle/${taskinfo.id}/1` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
                        }}>
                        {spandata(taskinfo.ofo,11)}
                        </Link>
                    }
                }
            
    },
    
    {
        title: 'Очно-заочная',
        key: 'ozf',
        render: taskinfo=> {
            if (taskinfo.ozf>0) {
            return <Link 
            to={{
              pathname: `/tasksingle/${taskinfo.id}/7` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
            }}>
            {spandata(taskinfo.ozf,11)}
            </Link>
        }
    }
        
    },
    
    {
        title: 'Заочная форма',
        key: 'totalzfo',
        render: taskinfo=> {
            if (taskinfo.zf>0) {
            return <Link 
            to={{
              pathname: `/tasksingle/${taskinfo.id}/2` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
            }}>
            {spandata(taskinfo.zf,11)}
            </Link>
        }
    }
    },
        
    ];
    
    useEffect(() => {
        setLoading(true);
        const idtask = parseInt(props.match.params.id, 10);
        API_getstatoop(idtask).then(data => {
            let index = 0;
            settaskinfo(
                data.map(s => {
                    index++;
                    return {
                        ...s,
                        rowNumber: index,
                    };
                })
            );
        })
        .finally(() => setLoading(false));
    }, [props.match.params.id]);
    
            
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <PageTitle title="Статистика по загрузке документов (количество документов)" />
            <Menu
                            mode="horizontal"
                            style={{ height: '100%' }}
                            activeKey={props.history.location.pathname}
                            selectedKeys={[props.history.location.pathname]}
                        >
                            
                            <Menu.Item key="/bak">
                                <NavLink to="/stat/03">
                                    Бакалавриат
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/spec">
                                <NavLink to="/stat/05">
                                    Специалитет
                                </NavLink>
                            </Menu.Item>

                            <Menu.Item key="/mag">
                                <NavLink to="/stat/04">
                                    Магистратура
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/asp">
                                <NavLink to="/stat/06">
                                    Аспирантура
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/ord">
                                <NavLink to="/stat/08">
                                    Ординатура
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/spo">
                                <NavLink to="/stat/02">
                                    СПО
                                </NavLink>
                            </Menu.Item>
                        </Menu>
            <Spin  spinning={loading} tip="Подождите идет сбор информации..">            
            <Table columns={columns} dataSource={taskinfo} bordered size="small" pagination={false}
                rowKey ={record => record.id} 

                />
                </Spin>
        </>
        

    )
}

export default StatOOP
