import axios from './../utils/axios';
import { notification } from 'antd';

export const API_gettaskoop = (lvl,year) => {
    return axios({
        method: 'GET',
        url: `GetTaskOOP/${lvl}/${year}`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_getstatoop = (lvl) => {
    return axios({
        method: 'GET',
        url: `stat/${lvl}`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};
// обновление ООП
export const API_refreshoop = () => {

    notification.info({
        message: 'Начало загрузки ООП и документов из Гугл таблицы, подождите 10 секунд',
      });
return axios({
        method: 'GET',
        url: `oop/refresh`,
    })
        .then(function(response) {
            notification.success({
                message: 'Загрузка прошла успешно',
              });
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла при обновлении данных' });
            }
        });
};

export const Api_Save_Status =(idFile,status)=> {
    return axios({
        method: 'GET',
        url: `/file/setstatus/${idFile}/${status}`,
    })
        .then(function(response) {
            response.data.status=1;
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};



export const Api_Get_Status =(idFile)=> {
    return axios({
        method: 'GET',
        url: `/file/getstatus/${idFile}`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};