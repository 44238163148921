// ООП программа берется uid
import axios from './../utils/axios';
import { notification } from 'antd';
// процедура Get_Slide2
export const API_GetDocument = (id,form) => {
    return axios({
        method: 'get',
        url: `oopdetail/${id}/${form}`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};
