import React from 'react';
import {Button,Input,Icon} from 'antd';
import Highlighter from 'react-highlight-words';

//import {SearchOutlined } from '@ant-design/icons/SearchOutlined';

    


const getColumnSearchProps = (dataIndex, title,searchedColumn,searchText) => {
    const searchInput = React.createRef();
  
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
          
          searchText =selectedKeys[0];
          searchedColumn = dataIndex;
      };
      const handleReset = clearFilters => {
        clearFilters();
        searchText='';
      };
      
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={title}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Поиск
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Сброс
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: text => (
        <Highlighter
          highlightStyle={{ backgroundColor: "yellow", color: "blue",padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text && text.toString()}
        />
      )
    };
  };
  
  export default getColumnSearchProps;