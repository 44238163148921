
import React from 'react';
import { Form, Button} from 'antd';
import { DirectAPI_login } from '../api/auth';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { setUser } from '../redux/actions';

const DirectLogin = (props) => {
    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err) => {
            if (!err) {
      console.log(props.match.params.session);
      DirectAPI_login(props.match.params.session)
                         .then(response => {
                        console.log(response);
                        if (response.token!=='') {
                        props.setUser(response.username);
                        localStorage.setItem('accessToken', response.token);
                        localStorage.setItem('login', response.username);
                        notification.success({ message: `Добро пожаловать ${response.fio}!` });
                        props.history.push('/');
                        } else {
                            notification.error({ message: `ошибка данных` });    
                            props.history.push('/login');
                        }
                    })
                    .catch(() => {
                        notification.error({ message: 'Пользователь с таким логином или паролем не найден' });
                    });
                } else {
                    notification.error({ message: 'Что то пошло не так' });
                }
            });
        };
    
    return (
        <Form onSubmit={handleSubmit} style={{ width: 300, margin: '0 auto' }}>
            <p style={{ fontWeight: 300, textAlign: 'center', fontSize: 14, marginBottom: 10 }}>После нажатия кнопки вход вы перейдете на сайт</p>
          
            <Form.Item style={{ marginBottom: 0 }}>
                <Button type="primary" htmlType="submit" block size="large">
                    Вход
                </Button>
            </Form.Item>
        </Form>
    );
};

const WrappedDirectForm = Form.create()(DirectLogin);

export default connect(
    null,
    { setUser }
)(WrappedDirectForm);